var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"member_list_container\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <div class=\"member_list_loading_indicator\"></div>\n    <table class=\"member-list table table-striped\">\n        <thead class=\"table-sticky-headers\">\n            <th data-sort=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":46}}}))
    + "</th>\n            <th class=\"settings-email-column\" data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":64},"end":{"line":6,"column":78}}}))
    + "</th>\n            <th class=\"user-remove-actions\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_remove_members"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":105}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":106},"end":{"line":7,"column":122}}}))
    + "</th>\n        </thead>\n        <tbody class=\"member_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This group has no members.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":83}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No group members match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":112},"end":{"line":9,"column":163}}}))
    + "\"></tbody>\n    </table>\n</div>\n";
},"useData":true});