var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\" id=\"integration-url-branches-parameter\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" id=\"integration-url-all-branches\" class=\"integration-url-parameter\" checked/>\n        <span class=\"rendered-checkbox\"></span>\n    </label>\n    <label class=\"inline\" for=\"integration-url-all-branches\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send notifications for all branches",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":51}}}))
    + "\n    </label>\n</div>\n<div class=\"input-group hide\" id=\"integration-url-filter-branches\">\n    <label for=\"integration-url-branches-text\" class=\"modal-label-field\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Which branches should notifications be sent for?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":64}}}))
    + "\n        (<i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"comma-separated list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":40}}}))
    + "</i>)\n    </label>\n    <input type=\"text\" id=\"integration-url-branches-text\" class=\"modal_text_input integration-url-parameter\" value=\"main\"/>\n</div>\n";
},"useData":true});