var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"muted-user-settings\" class=\"settings-section\" data-name=\"muted-users\">\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Muted users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":31}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter muted users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":90},"end":{"line":4,"column":114}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":78}}}),"id":"muted_users_search"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"user_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":83}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"date_muted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date muted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":87}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"muted_users_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not muted any users yet.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":95}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No users match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":124},"end":{"line":13,"column":168}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});