var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"add-new-saved-snippet-modal\" class=\"new-style\">\n    <form id=\"add-new-saved-snippet-form\" class=\"new-style\">\n        <label for=\"title\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":67}}}))
    + "</label>\n        <input id=\"new-saved-snippet-title\" type=\"text\" name=\"title\" class=\"modal_text_input saved-snippet-title\" value=\"\" autocomplete=\"off\" spellcheck=\"false\" autofocus=\"autofocus\"/>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Content",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":13},"end":{"line":5,"column":29}}}))
    + "</div>\n        <textarea class=\"settings_textarea saved-snippet-content\" rows=\"4\">"
    + alias2(container.lambda(container.strict(depth0, "prepopulated_content", {"start":{"line":7,"column":15},"end":{"line":7,"column":35}} ), depth0))
    + "</textarea>\n    </form>\n</div>\n";
},"useData":true});